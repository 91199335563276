import React from "react";

export default function Contact() {
  return (
    <>
      <section class="py-5">
        <div class="container px-5">
          <div class="bg-light rounded-3 py-5 px-4 px-md-5 mb-5">
            <div class="text-center mb-5">
              <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                <i class="bi bi-envelope"></i>
              </div>
              <h1 class="fw-bolder">Get in Touch with TickTraq</h1>
              <p class="lead fw-normal text-muted mb-0">
                Have questions or feedback? We'd love to hear from you.
              </p>
            </div>
            <div class="row gx-5 justify-content-center">
              <div class="col-lg-8 col-xl-6">
                <form id="contactForm">
                  <div class="form-floating mb-3">
                    <input
                      class="form-control"
                      id="name"
                      type="text"
                      placeholder="Enter your name..."
                      required
                    />
                    <label for="name">Full Name</label>
                  </div>

                  <div class="form-floating mb-3">
                    <input
                      class="form-control"
                      id="email"
                      type="email"
                      placeholder="name@example.com"
                      required
                    />
                    <label for="email">Email Address</label>
                  </div>

                  <div class="form-floating mb-3">
                    <input
                      class="form-control"
                      id="phone"
                      type="tel"
                      placeholder="(123) 456-7890"
                    />
                    <label for="phone">Phone Number (Optional)</label>
                  </div>

                  <div class="form-floating mb-3">
                    <textarea
                      class="form-control"
                      id="message"
                      type="text"
                      placeholder="Enter your message here..."
                      style={{ height: "10rem" }}
                      required
                    ></textarea>
                    <label for="message">Message</label>
                  </div>

                  <div class="d-grid">
                    <button class="btn btn-primary btn-lg" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="row gx-5 row-cols-2 row-cols-lg-4 py-5">
            <div class="col">
              <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                <i class="bi bi-chat-dots"></i>
              </div>
              <div class="h5 mb-2">Chat with Us</div>
              <p class="text-muted mb-0">
                Chat live with a TickTraq support specialist for instant
                answers.
              </p>
            </div>
            <div class="col">
              <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                <i class="bi bi-people"></i>
              </div>
              <div class="h5">Join the Community</div>
              <p class="text-muted mb-0">
                Explore the TickTraq forums and connect with other users.
              </p>
            </div>
            <div class="col">
              <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                <i class="bi bi-question-circle"></i>
              </div>
              <div class="h5">Visit Our Support Center</div>
              <p class="text-muted mb-0">
                Find answers in our comprehensive FAQ and support articles.
              </p>
            </div>
            <div class="col">
              <div class="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                <i class="bi bi-telephone"></i>
              </div>
              <div class="h5">Give Us a Call</div>
              <p class="text-muted mb-0">
                Prefer talking? Call our support team at (555) 892-9403.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
