import React from "react";

export default function Setup() {
  return (
    <>
      <header className="py-5">
        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xxl-6">
              <div className="text-center my-5">
                <h1 className="fw-bolder mb-3">
                  Get Started with TickTraq in Minutes.
                </h1>
                <p className="lead fw-normal text-muted mb-4">
                  TickTraq simplifies investment tracking with real-time updates
                  on your stocks and crypto, right at your fingertips. Setting
                  up your device and app is quick and straightforward. Let's get
                  you started.
                </p>
                <a className="btn btn-primary btn-lg" href="#scroll-target">
                  Begin Setup
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="py-5 bg-light" id="scroll-target">
        <div className="container px-5 my-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <img
                className="img-fluid rounded mb-5 mb-lg-0"
                src="https://dummyimage.com/600x400/343a40/6c757d" // Replace with a relevant image for the mobile app setup
                alt="TickTraq Mobile App Setup"
              />
            </div>
            <div className="col-lg-6">
              <h2 className="fw-bolder">Setting Up the Mobile App</h2>
              <p className="lead fw-normal text-muted mb-0">
                Download the TickTraq app from your preferred app store. Create
                an account or log in, then pair your device via Bluetooth.
                Customize your tickers, set alerts, and start tracking your
                investments seamlessly, wherever you are.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6 order-first order-lg-last">
              <img
                className="img-fluid rounded mb-5 mb-lg-0"
                src="https://dummyimage.com/600x400/343a40/6c757d" // Replace with a relevant image for the web app setup
                alt="TickTraq Web App Setup"
              />
            </div>
            <div className="col-lg-6">
              <h2 className="fw-bolder">Using the Web App</h2>
              <p className="lead fw-normal text-muted mb-0">
                Access TickTraq from any web browser to monitor your portfolio
                with an even broader view. Log in to sync your device, customize
                your display settings, and analyze your investment data with
                advanced tools available on the web platform.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section class="py-5 bg-light">
        <div class="container px-5 my-5">
          <div class="text-center">
            <h2 class="fw-bolder">Our team</h2>
            <p class="lead fw-normal text-muted mb-5">
              Dedicated to quality and your success
            </p>
          </div>
          <div class="row gx-5 row-cols-1 row-cols-sm-2 row-cols-xl-4 justify-content-center">
            <div class="col mb-5 mb-5 mb-xl-0">
              <div class="text-center">
                <img
                  class="img-fluid rounded-circle mb-4 px-4"
                  src="https://dummyimage.com/150x150/ced4da/6c757d"
                  alt="..."
                />
                <h5 class="fw-bolder">Ibbie Eckart</h5>
                <div class="fst-italic text-muted">Founder &amp; CEO</div>
              </div>
            </div>
            <div class="col mb-5 mb-5 mb-xl-0">
              <div class="text-center">
                <img
                  class="img-fluid rounded-circle mb-4 px-4"
                  src="https://dummyimage.com/150x150/ced4da/6c757d"
                  alt="..."
                />
                <h5 class="fw-bolder">Arden Vasek</h5>
                <div class="fst-italic text-muted">CFO</div>
              </div>
            </div>
            <div class="col mb-5 mb-5 mb-sm-0">
              <div class="text-center">
                <img
                  class="img-fluid rounded-circle mb-4 px-4"
                  src="https://dummyimage.com/150x150/ced4da/6c757d"
                  alt="..."
                />
                <h5 class="fw-bolder">Toribio Nerthus</h5>
                <div class="fst-italic text-muted">Operations Manager</div>
              </div>
            </div>
            <div class="col mb-5">
              <div class="text-center">
                <img
                  class="img-fluid rounded-circle mb-4 px-4"
                  src="https://dummyimage.com/150x150/ced4da/6c757d"
                  alt="..."
                />
                <h5 class="fw-bolder">Malvina Cilla</h5>
                <div class="fst-italic text-muted">CTO</div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}
