// import logo from "./logo.svg";
import "../App.css";
import StlViewer from "../components/StlViewer";
import Case from "../static/Case.stl";
export default function Home() {
  const style = {
    top: 0,
    left: 0,
    width: "600px",
    height: "400px",
  };
  const modelProps = {
    scale: 1.5,
  };

  return (
    <div>
      <main className="flex-shrink-0">
        <header className="bg-dark py-5">
          <div className="container px-5">
            <div className="row gx-5 align-items-center justify-content-center">
              <div className="col-lg-8 col-xl-7 col-xxl-6">
                <div className="my-5 text-center text-xl-start">
                  <h1 className="display-5 fw-bolder text-white mb-2 text-center">
                    Track Investments Effortlessly with TickTraq
                  </h1>
                  <p className="lead fw-normal text-white-50 mb-4 text-center">
                    Whether you prefer a compact 2.9-inch or an expansive
                    5.79-inch e-paper display, TickTraq offers crystal-clear
                    visibility for your stocks and crypto. Experience seamless
                    investment tracking with real-time data and a sleek design.
                  </p>
                  <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-center">
                    <a
                      className="btn btn-success btn-lg px-4 me-sm-3"
                      href="#features"
                    >
                      Discover TickTraq
                    </a>
                    {/* <a
                      className="btn btn-danger btn-lg px-4 me-sm-3"
                      href="#features"
                    >
                      Pre-Order Now
                    </a> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center">
                <StlViewer
                  style={style}
                  orbitControls
                  shadows
                  url={Case}
                  modelProps={modelProps}
                />
                {/* <img
                  className="img-fluid rounded-3 my-5"
                  src="https://dummyimage.com/600x400/343a40/6c757d"
                  alt="TickTraq Demo"
                /> */}
              </div>
            </div>
          </div>
        </header>
        <section className="py-5" id="features">
          <div className="container px-5 my-5">
            <div className="row gx-5">
              <div className="col-lg-4 mb-5 mb-lg-0">
                <h2 className="fw-bolder mb-0">Why TickTraq?</h2>
              </div>
              <div className="col-lg-8">
                <div className="row gx-5 row-cols-1 row-cols-md-2">
                  {/* Feature: Innovative Design */}
                  <div className="col mb-5 h-100">
                    <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                      <i className="bi bi-arrows-angle-expand"></i>
                    </div>
                    <h2 className="h5">Innovative Design</h2>
                    <p className="mb-0">
                      Rotatable design for horizontal and vertical display,
                      accommodating multiple tickers.
                    </p>
                  </div>
                  {/* Feature: Real-Time Updates */}
                  <div className="col mb-5 h-100">
                    <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                      <i className="bi bi-graph-up-arrow"></i>
                    </div>
                    <h2 className="h5">Real-Time Updates</h2>
                    <p className="mb-0">
                      Get the latest on your stocks and crypto with real-time
                      tracking.
                    </p>
                  </div>
                  {/* Feature: Programmable Convenience */}
                  <div className="col mb-5 mb-md-0 h-100">
                    <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                      <i className="bi bi-gear"></i>
                    </div>
                    <h2 className="h5">Programmable Convenience</h2>
                    <p className="mb-0">
                      Easily customize via web or mobile app through Bluetooth.
                    </p>
                  </div>
                  {/* Feature: Eco-Friendly Display */}
                  <div className="col h-100">
                    <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                      <i className="bi bi-sun"></i>
                    </div>
                    <h2 className="h5">Eco-Friendly Display</h2>
                    <p className="mb-0">
                      Energy-efficient e-paper display that's easy on the eyes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="py-5 bg-light">
          <div className="container px-5 my-5">
            <div className="row gx-5 justify-content-center">
              <div className="col-lg-10 col-xl-7">
                <div className="text-center">
                  <div className="fs-4 mb-4 fst-italic">
                    "TickTraq has completely transformed the way I track my
                    investment portfolio. Its intuitive design and real-time
                    updates allow me to stay informed without being glued to my
                    phone or computer. A must-have for any serious investor!"
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <img
                      className="rounded-circle me-3"
                      src="https://dummyimage.com/40x40/ced4da/6c757d"
                      alt="User Testimonial"
                    />
                    <div className="fw-bold">
                      Jordan Smith
                      <span className="fw-bold text-primary mx-1">/</span>
                      Beta Tester, TickTraq
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="py-5">
          <div className="container px-5 my-5">
            <div className="row gx-5 justify-content-center">
              <div className="col-lg-8 col-xl-6">
                <div className="text-center">
                  <h2 className="fw-bolder">Latest from TickTraq</h2>
                  <p className="lead fw-normal text-muted mb-5">
                    Stay up to date with the latest developments, user stories,
                    and insights on how TickTraq is revolutionizing investment
                    tracking.
                  </p>
                </div>
              </div>
            </div>
            <div className="row gx-5">
              {/* Blog Post 1 */}
              <div className="col-lg-4 mb-5">
                <div className="card h-100 shadow border-0">
                  <img
                    className="card-img-top"
                    src="https://dummyimage.com/600x350/ced4da/6c757d"
                    alt="TickTraq Development Update"
                  />
                  <div className="card-body p-4">
                    <div className="badge bg-primary bg-gradient rounded-pill mb-2">
                      Development
                    </div>
                    <a
                      className="text-decoration-none link-dark stretched-link"
                      href="#!"
                    >
                      <h5 className="card-title mb-3">
                        TickTraq's Journey: From Concept to Prototype
                      </h5>
                    </a>
                    <p className="card-text mb-0">
                      Discover the story behind TickTraq’s development and the
                      challenges we overcame to bring this innovative product to
                      you.
                    </p>
                  </div>
                  <div className="card-footer p-4 pt-0 bg-transparent border-top-0">
                    <div className="d-flex align-items-end justify-content-between">
                      <div className="small">
                        <div className="fw-bold">The TickTraq Team</div>
                        <div className="text-muted">
                          March 12, 2023 &middot; 5 min read
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Blog Post 2 */}
              <div className="col-lg-4 mb-5">
                <div className="card h-100 shadow border-0">
                  <img
                    className="card-img-top"
                    src="https://dummyimage.com/600x350/adb5bd/495057"
                    alt="TickTraq User Experience"
                  />
                  <div className="card-body p-4">
                    <div className="badge bg-primary bg-gradient rounded-pill mb-2">
                      User Stories
                    </div>
                    <a
                      className="text-decoration-none link-dark stretched-link"
                      href="#!"
                    >
                      <h5 className="card-title mb-3">
                        How TickTraq Changed the Game for Investor Jane Doe
                      </h5>
                    </a>
                    <p className="card-text mb-0">
                      Read about how TickTraq has become an indispensable tool
                      for Jane Doe, a seasoned investor, in tracking her diverse
                      portfolio.
                    </p>
                  </div>
                  <div className="card-footer p-4 pt-0 bg-transparent border-top-0">
                    <div className="d-flex align-items-end justify-content-between">
                      <div className="small">
                        <div className="fw-bold">Jane Doe</div>
                        <div className="text-muted">
                          March 23, 2023 &middot; 4 min read
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Blog Post 3 */}
              <div className="col-lg-4 mb-5">
                <div className="card h-100 shadow border-0">
                  <img
                    className="card-img-top"
                    src="https://dummyimage.com/600x350/6c757d/343a40"
                    alt="TickTraq Future Vision"
                  />
                  <div className="card-body p-4">
                    <div className="badge bg-primary bg-gradient rounded-pill mb-2">
                      Vision
                    </div>
                    <a
                      className="text-decoration-none link-dark stretched-link"
                      href="#!"
                    >
                      <h5 className="card-title mb-3">
                        What's Next for TickTraq? Our Roadmap to Revolutionizing
                        Investment Tracking
                      </h5>
                    </a>
                    <p className="card-text mb-0">
                      Take a glimpse into the future of TickTraq, including
                      upcoming features and enhancements planned for our
                      innovative tracker.
                    </p>
                  </div>
                  <div className="card-footer p-4 pt-0 bg-transparent border-top-0">
                    <div className="d-flex align-items-end justify-content-between">
                      <div className="small">
                        <div className="fw-bold">The TickTraq Team</div>
                        <div className="text-muted">
                          April 2, 2023 &middot; 6 min read
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <aside className="bg-primary bg-gradient rounded-3 p-4 p-sm-5 mt-5">
              <div className="d-flex align-items-center justify-content-between flex-column flex-xl-row text-center text-xl-start">
                <div className="mb-4 mb-xl-0">
                  <div className="fs-3 fw-bold text-white">
                    Join the TickTraq Community
                  </div>
                  <div className="text-white-50">
                    Sign up for our newsletter to receive the latest updates and
                    exclusive offers.
                  </div>
                </div>
                <div className="ms-xl-4">
                  <div className="input-group mb-2">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Email address..."
                      aria-label="Email address..."
                      aria-describedby="button-newsletter"
                    />
                    <button
                      className="btn btn-outline-light"
                      id="button-newsletter"
                      type="button"
                    >
                      Sign Up
                    </button>
                  </div>
                  <div className="small text-white-50">
                    We care about your privacy and will never share your
                    information.
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </section>
      </main>
    </div>
  );
}
