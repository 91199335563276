import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";
export default function Pricing() {
  return (
    <>
      <section class="bg-light py-5">
        <div class="container px-5 my-5">
          <div class="text-center mb-5">
            <h1 class="fw-bolder">
              Pay as you grow{" "}
              <FontAwesomeIcon
                style={{ color: "green" }}
                icon={faArrowTrendUp}
              />
            </h1>
            <p class="lead fw-normal text-muted mb-0">
              Our pricing reflects the true costs of providing accurate and{" "}
              <b>commercial-use</b> stock market data, which starts around
              $2,000/mo.
              <br />
              We strive for transparency and value in our services. If you have
              any questions or need more information, please feel free to{" "}
              <a href="contact">contact</a> us.
            </p>
          </div>

          <div class="row gx-5 justify-content-center">
            {/* <!-- Pricing card free --> */}
            <div class="col-lg-6 col-xl-4">
              <div class="card mb-5 mb-xl-0">
                <div class="card-body p-5">
                  <div class="small text-uppercase fw-bold text-muted">
                    Free
                  </div>
                  <div class="mb-3">
                    <span class="display-4 fw-bold">$0</span>
                    <span class="text-muted">/ mo.</span>
                  </div>
                  <ul class="list-unstyled mb-4">
                    <li class="mb-2">
                      <i class="bi bi-check text-primary"></i>
                      <strong>Crypto Tracking</strong> (Real-time updates)
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-check text-primary"></i>
                      <strong>Stock Tracking</strong> (15-minute delay)
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-check text-primary"></i>
                      Unlimited Devices
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-check text-primary"></i>
                      Community access
                    </li>
                  </ul>
                  <div class="d-grid">
                    <a class="btn btn-outline-primary" href="#!">
                      Choose plan
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Pricing card pro --> */}
            <div class="col-lg-6 col-xl-4">
              <div class="card mb-5 mb-xl-0">
                <div class="card-body p-5">
                  <div class="small text-uppercase fw-bold">
                    <i class="bi bi-star-fill text-warning"></i>
                    Premium (Monthly)
                  </div>
                  <div class="mb-3">
                    <span class="display-4 fw-bold">$2.50</span>
                    <span class="text-muted">/ mo.</span>
                  </div>
                  <ul class="list-unstyled mb-4">
                    <li class="mb-2">
                      <i class="bi bi-check text-primary"></i>
                      <strong>Stocks & Crypto</strong> (Real-time updates)
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-check text-primary"></i>
                      Unlimited Devices
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-check text-primary"></i>
                      Community access
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-check text-primary"></i>
                      Premium Support
                    </li>
                  </ul>
                  <div class="d-grid">
                    <a class="btn btn-primary" href="#!">
                      Choose plan
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Pricing card enterprise --> */}
            <div class="col-lg-6 col-xl-4">
              <div class="card">
                <div class="card-body p-5">
                  <div class="small text-uppercase fw-bold text-muted">
                    Premium (Annual)
                  </div>
                  <div class="mb-3">
                    <span class="display-4 fw-bold">$25</span>
                    <span class="text-muted">/ yr.</span>
                  </div>
                  <ul class="list-unstyled mb-4">
                    <li class="mb-2">
                      <i class="bi bi-check text-primary"></i>
                      <strong>Stocks & Crypto</strong> (Real-time updates)
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-check text-primary"></i>
                      Unlimited Devices
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-check text-primary"></i>
                      Dedicated Premium Support
                    </li>
                    <li class="mb-2">
                      <i class="bi bi-check text-primary"></i>
                      Community access
                    </li>
                  </ul>
                  <div class="d-grid">
                    <a class="btn btn-outline-primary" href="#!">
                      Choose plan
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
