import { useEffect, useState } from "react";
import Plotly from "plotly.js-dist-min";

export const useChartImage = () => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://data-api.binance.vision/api/v3/klines?symbol=BTCUSDT&interval=1m&limit=30"
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch: ${response.statusText}`);
        }
        const rawData = await response.json();

        // Candlestick data setup
        const processedData = [
          {
            x: rawData.map((d) => new Date(d[0]).toISOString()),
            close: rawData.map((d) => +d[4]),
            high: rawData.map((d) => +d[2]),
            low: rawData.map((d) => +d[3]),
            open: rawData.map((d) => +d[1]),
            type: "candlestick",
            decreasing: { line: { color: "black" }, fillcolor: "black" },
            increasing: { line: { color: "black" }, fillcolor: "#F8F8F8" },
          },
        ];

        // Chart layout configuration
        const layout = {
          width: 1000,
          height: 500,
          title: {
            text: "BTC/USD",
            font: {
              size: 36,
              color: "#333",
            },
            x: 0.5,
            y: 0.9,
            xanchor: "center",
            yanchor: "top",
          },
          paper_bgcolor: "#F8F8F8",
          plot_bgcolor: "#F8F8F8",
          margin: { l: 35, r: 80, b: 38, t: 62 },

          showlegend: false,
          xaxis: {
            visible: true,
            showticklabels: true,
            rangeslider: { visible: false },
          },
          yaxis: {
            visible: true,
            showticklabels: true,
            side: "right",
          },
        };

        // Use an off-screen div to plot
        const gd = document.createElement("div");
        document.body.appendChild(gd); // Append gd to the body to ensure it's in the DOM

        await Plotly.newPlot(gd, processedData, layout);
        const url = await Plotly.toImage(gd, { format: "png" });
        setImageUrl(url);

        // Cleanup: Remove the gd element after image generation
        document.body.removeChild(gd);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 10000); // Refresh data every 10 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  return imageUrl;
};
