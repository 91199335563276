import React, { useMemo, useRef, useState } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { OrbitControls, Environment } from "@react-three/drei";
import * as THREE from "three";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { useChartImage } from "./ChartImageGenerator";
import { lerp } from "three/src/math/MathUtils";
import { useEffect } from "react";
import Case from "../static/Case.stl";

const Screen = ({ imageUrl }) => {
  const [texture, setTexture] = useState();

  useEffect(() => {
    if (imageUrl) {
      new THREE.TextureLoader().load(imageUrl, (loadedTexture) => {
        setTexture(loadedTexture);
      });
    }
  }, [imageUrl]);

  if (!texture) return null;
  const degrees = -30;
  const radians = degrees * (Math.PI / 180);
  const chartWidth = 78; // Example width
  const chartHeight = 40; // Example height, adjust these values as needed

  return (
    <mesh position={[0, 2.25, 4.75]} rotation={[radians, 0, 0]}>
      <planeGeometry attach="geometry" args={[chartWidth, chartHeight]} />
      <meshBasicMaterial map={texture} />
    </mesh>
  );
};

const RotatingSTLModel = ({ imageUrl }) => {
  const meshRef = useRef();
  const [isHovered, setIsHovered] = useState(false);
  const [rotationSpeed, setRotationSpeed] = useState(0.01);
  // New state variables for target rotations
  //   const [targetRotationX, setTargetRotationX] = useState(0);
  //   const [targetRotationZ, setTargetRotationZ] = useState(0);

  useFrame(() => {
    if (meshRef.current) {
      // When hovered, stop the rotation by setting the speed to 0
      const targetSpeed = isHovered ? 0 : 0.01;
      setRotationSpeed(lerp(rotationSpeed, targetSpeed, 0.1));

      // Rotate the model
      meshRef.current.rotation.y += rotationSpeed;

      // Smoothly interpolate the rotation towards the target rotations on x and z axes
      meshRef.current.rotation.x = lerp(
        meshRef.current.rotation.x,
        // targetRotationX,
        0,
        0.1
      );
      meshRef.current.rotation.z = lerp(
        meshRef.current.rotation.z,
        // targetRotationZ,
        0,
        0.1
      );
    }
  });

  const geometry = useLoader(STLLoader, Case);
  useMemo(() => geometry.center(), [geometry]);

  const degrees = -90;
  const radians = degrees * (Math.PI / 180);

  return (
    <mesh
      rotation={[radians, 0, 0]}
      ref={meshRef}
      onPointerOver={() => {
        setIsHovered(true);
        // Optionally, set specific target rotations upon hover
        // For example, to tilt slightly:
        // setTargetRotationX(Math.PI / 20);
        // setTargetRotationZ(Math.PI / 20);
      }}
      onPointerOut={() => {
        setIsHovered(false);
        // setTargetRotationX(0);
        // setTargetRotationZ(0);
      }}
      geometry={geometry}
      material={new THREE.MeshStandardMaterial({ color: "gray" })}
    >
      <Screen imageUrl={imageUrl} />
    </mesh>
  );
};

export default function StlViewer() {
  const imageUrl = useChartImage();
  console.log(imageUrl);
  return (
    <Canvas
      camera={{ position: [100, 100, 100], fov: 30 }}
      style={{ width: "600px", height: "400px" }}
    >
      <ambientLight intensity={0.5} />
      <spotLight position={[10, 15, 10]} angle={0.3} castShadow />
      <OrbitControls />
      <Environment preset="sunset" />
      <RotatingSTLModel imageUrl={imageUrl} />
    </Canvas>
  );
}
