import React from "react";

export default function FAQ() {
  return (
    <>
      <section class="py-5">
        <div class="container px-5 my-5">
          <div class="text-center mb-5">
            <h1 class="fw-bolder">Frequently Asked Questions</h1>
            <p class="lead fw-normal text-muted mb-0">How can we help you?</p>
          </div>
          <div class="row gx-5">
            <div class="col-xl-8">
              {/* <!-- FAQ Accordion 1 --> */}
              <h2 class="fw-bolder mb-3">Getting Started</h2>
              <div class="accordion mb-5" id="accordionGettingStarted">
                {/* <!-- Item 1 --> */}
                <div class="accordion-item">
                  <h3 class="accordion-header" id="headingOneGS">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOneGS"
                      aria-expanded="true"
                      aria-controls="collapseOneGS"
                    >
                      How do I set up my TickTraq device?
                    </button>
                  </h3>
                  <div
                    class="accordion-collapse collapse show"
                    id="collapseOneGS"
                    aria-labelledby="headingOneGS"
                    data-bs-parent="#accordionGettingStarted"
                  >
                    <div class="accordion-body">
                      Setting up your TickTraq device is easy! Simply power it
                      on, connect to it via Bluetooth using our mobile app, and
                      follow the on-screen instructions to select your preferred
                      stock and crypto tickers.
                    </div>
                  </div>
                </div>
                {/* <!-- Item 2 --> */}
                <div class="accordion-item">
                  <h3 class="accordion-header" id="headingTwoGS">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwoGS"
                      aria-expanded="false"
                      aria-controls="collapseTwoGS"
                    >
                      Can I track both stocks and crypto with TickTraq?
                    </button>
                  </h3>
                  <div
                    class="accordion-collapse collapse"
                    id="collapseTwoGS"
                    aria-labelledby="headingTwoGS"
                    data-bs-parent="#accordionGettingStarted"
                  >
                    <div class="accordion-body">
                      Absolutely! TickTraq supports tracking for both stocks and
                      cryptocurrencies. You can customize your ticker display
                      via our mobile or web app to monitor your investments in
                      real-time.
                    </div>
                  </div>
                </div>
                {/* <!-- Item 3 --> */}
                <div class="accordion-item">
                  <h3 class="accordion-header" id="headingThreeGS">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThreeGS"
                      aria-expanded="false"
                      aria-controls="collapseThreeGS"
                    >
                      What mobile devices are compatible with TickTraq?
                    </button>
                  </h3>
                  <div
                    class="accordion-collapse collapse"
                    id="collapseThreeGS"
                    aria-labelledby="headingThreeGS"
                    data-bs-parent="#accordionGettingStarted"
                  >
                    <div class="accordion-body">
                      TickTraq is compatible with most iOS and Android devices.
                      Ensure your device's Bluetooth is enabled and that it's
                      running the latest version of our app for the best
                      experience.
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- FAQ Accordion 2 --> */}
              <h2 class="fw-bolder mb-3">Billing & Account</h2>
              <div class="accordion mb-5" id="accordionBillingAccount">
                {/* <!-- Item 1 --> */}
                <div class="accordion-item">
                  <h3 class="accordion-header" id="headingOneBA">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOneBA"
                      aria-expanded="true"
                      aria-controls="collapseOneBA"
                    >
                      How do I update my billing information?
                    </button>
                  </h3>
                  <div
                    class="accordion-collapse collapse show"
                    id="collapseOneBA"
                    aria-labelledby="headingOneBA"
                    data-bs-parent="#accordionBillingAccount"
                  >
                    <div class="accordion-body">
                      To update your billing information, log into your TickTraq
                      account on our website, go to the "Account Settings"
                      section, and select "Billing." From there, you can update
                      your payment methods and billing address.
                    </div>
                  </div>
                </div>
                {/* <!-- Item 2 --> */}
                <div class="accordion-item">
                  <h3 class="accordion-header" id="headingTwoBA">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwoBA"
                      aria-expanded="false"
                      aria-controls="collapseTwoBA"
                    >
                      What are the subscription plans for TickTraq?
                    </button>
                  </h3>
                  <div
                    class="accordion-collapse collapse"
                    id="collapseTwoBA"
                    aria-labelledby="headingTwoBA"
                    data-bs-parent="#accordionBillingAccount"
                  >
                    <div class="accordion-body">
                      TickTraq offers a free plan with basic features and a
                      premium subscription for advanced tracking and analysis.
                      Visit our pricing page for more details on the plans and
                      features included.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4">
              <div class="card border-0 bg-light mt-xl-5">
                <div class="card-body p-4 py-lg-5">
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="text-center">
                      <div class="h6 fw-bolder">Have more questions?</div>
                      <p class="text-muted mb-4">
                        Reach out to us directly at{" "}
                        <a href="mailto:support@ticktraq.com">
                          support@ticktraq.com
                        </a>
                      </p>
                      <div class="h6 fw-bolder">Follow us</div>
                      <a
                        class="fs-5 px-2 link-dark"
                        href="https://twitter.com/ticktraq"
                      >
                        <i class="bi-twitter"></i>
                      </a>
                      <a
                        class="fs-5 px-2 link-dark"
                        href="https://facebook.com/ticktraq"
                      >
                        <i class="bi-facebook"></i>
                      </a>
                      <a
                        class="fs-5 px-2 link-dark"
                        href="https://linkedin.com/company/ticktraq"
                      >
                        <i class="bi-linkedin"></i>
                      </a>
                      <a
                        class="fs-5 px-2 link-dark"
                        href="https://youtube.com/ticktraq"
                      >
                        <i class="bi-youtube"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
