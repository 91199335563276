import React from "react";

export default function Dev() {
  return (
    <section class="py-5 bg-dark">
      <div class="container px-5 my-5 text-white">
        <h3>About</h3>
        <a href="https://pypi.org/project/tinyticker/">
          Exactly this... But with ESP32
        </a>
        <br />
        <img
          src="https://pypi-camo.freetls.fastly.net/63983a00b8c3fc910de53c6ca3510890d86fc131/68747470733a2f2f692e696d6775722e636f6d2f4a346b3350434d2e706e67"
          width="350px"
          height="250px"
          alt="TinyTicker"
        />
        <p>
          I'm using this for now because this will be the api endpoints for the
          esp32 to connect, setup and do OTA updates.
        </p>
        <h3>Components:</h3>
        <ul>
          <li>
            <a href="https://www.waveshare.com/2.9inch-e-Paper.htm">
              2.9 inch epaper
            </a>
          </li>
          <li>
            <a href="https://jlcpcb.com/partdetail/3198301-ESP32_S3_WROOM_1N4R2/C2913203">
              ESP32-S3-WROOM-1-N4R2
            </a>
          </li>
          <li>
            <a href="https://www.18650batterystore.com/products/panasonic-ncr18500a?srsltid=AfmBOooI-MuY1MU5FK4WDLNgnxI4WdPHWV2T3e6gvoWEAkCQHAZWufqx7vk">
              18500 Battery
            </a>
          </li>

          <li>
            <a href="https://www.digikey.com/en/products/detail/keystone-electronics/254/9646025">
              Keystone Electronics Contact Clip (254) DigiKey
            </a>
            |
            <a href="https://jlcpcb.com/partdetail/KeystoneElectronics-254/C6993377">
              Keystone Electronics Contact Clip (254) JLCPCB
            </a>
          </li>
          <li>
            <a href="https://jlcpcb.com/partdetail/gswitch-GT_USB7090A/C3019714">
              USB C connector
            </a>
          </li>
          <li>
            <a href="https://jlcpcb.com/partdetail/TexasInstruments-LM1117IMPX_3_3NOPB/C23984">
              LM1117v33
            </a>
          </li>
          <li>
            <a href="https://jlcpcb.com/partdetail/SiliconLabs-CP2102GMR/C6568">
              Do we need a CP2012?
            </a>
          </li>
          <li>
            <a href="https://jlcpcb.com/partdetail/Nxp_Usa_inc-MMA8652FCR1/C48024">
              Accelorometer
            </a>
          </li>
          <li>Battery Charging IC</li>
        </ul>
        <h3>PCB Notes:</h3>
        <ul>
          <li>
            Based on the screen size, 18650 would fit, but leave no room for usb
            c port. 18500 would leave 15mm.
            <a href="Case.stl">Case Design STL w/ 18650</a>
          </li>
        </ul>
        <h3>Firmware:</h3>
        <p>
          Most of the firmware I have no issues setting up. I'll setup the BLE
          connection to web once the pbc design is ready.
        </p>
        <ul>
          <li>OTA Updates</li>
          <li>
            <a href="https://github.com/palmmaximilian/ReactNativeArduinoBLE">
              Bluetooth to mobile / web
            </a>
            for authentication token / setup
          </li>
          <li>
            Retrieve data and do a partial update every 15 seconds (full update
            every 1 hour)
          </li>
          <li>
            epaper example: <br />
            <img
              width="25%"
              height="25%"
              src="https://www.researchgate.net/publication/314796903/figure/fig3/AS:669353418702865@1536597699737/Box-and-Whisker-plot-of-the-distribution-of-observations-on-days-of-the-week-indicating.png"
              alt="epaper"
            />
            <img
              width="25%"
              height="25%"
              src="https://c8.alamy.com/comp/W224KR/japanese-candlestick-black-and-white-chart-showing-downtrend-market-on-white-background-W224KR.jpg"
              alt="epaper"
            />
          </li>
        </ul>
        <h3>API Endpoints:</h3>
        <h3>Product description:</h3>
        <ul>
          <li>
            This will be a stock / crypto tracker similar to the image below,
            but slimmer and full canldes.
          </li>
          <li>
            <img
              src="https://i.etsystatic.com/27827236/r/il/86941a/3594465397/il_1588xN.3594465397_c5y1.jpg"
              width="25%"
              height="25%"
              alt="epaper"
            />
          </li>
        </ul>
      </div>
    </section>
  );
}
